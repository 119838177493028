import { NgModule, OnInit } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { InvitationManagerComponent } from './components/invitation-manager/invitation-manager.component';
import { HomeComponent } from './components/home/home.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { WeddingGalleryComponent } from './components/wedding-gallery/wedding-gallery.component';
import { ProgramComponent } from './components/program/program.component';
import { PreparationsComponent } from './components/preparations/preparations.component';
import { ThingsToDoComponent } from './components/things-to-do/things-to-do.component';
import { AccommodationsComponent } from './components/accommodations/accommodations.component';
import { AdminComponent } from './components/admin/admin.component';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
  { path: 'home',  component: HomeComponent },
  { path: 'gallery',  component: GalleryComponent },
  { path: 'wedding-gallery',  component: WeddingGalleryComponent },
  { path: 'program',  component: ProgramComponent },
  { path: 'preparations',  component: PreparationsComponent },
  { path: 'invites',  component: InvitationManagerComponent },
  // { path: 'login',  component: LoginComponent },
  // { path: 'admin',  component: AdminComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
