// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCq8HmKshM5vY4xchHd9L5qPa4R6P6u9Mk",
    authDomain: "wedding-website-77031.firebaseapp.com",
    databaseURL: "https://wedding-website-77031.firebaseio.com",
    projectId: "wedding-website-77031",
    storageBucket: "wedding-website-77031.appspot.com",
    messagingSenderId: "955664527930",
    appId: "1:955664527930:web:c64272fc5b8405e5343b03",
    measurementId: "G-NPD54KJQMN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
