import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'preparations',
  templateUrl: './preparations.component.html',
  styleUrls: ['./preparations.component.scss']
})
export class PreparationsComponent implements OnInit {

  private videoTokens: Array<string> = [
    'cWXZTx1tm4k', 'OMrwcix41sY', 'C72ZzbIiD2w', 'toTIo7isLFY' 
  ];

  constructor(private sanitizer: DomSanitizer) { }

  /**
   * getEmbeddedURL
   */
  public getEmbeddedURL(videoToken:string) :SafeResourceUrl {
    var url = `https://www.youtube.com/embed/${videoToken}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);    
  }

  ngOnInit() {
  }

}
