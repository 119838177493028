import { Component, OnInit, Input } from '@angular/core';

class Particle {
  public column: number;
  public row: number;
  public delay: number;
  public duration: number;
  public left: number;
  public top: number;
  public animation: string;
}

@Component({
  selector: 'app-particles',
  templateUrl: './particles.component.html',
  styleUrls: ['./particles.component.scss']
})
export class ParticlesComponent implements OnInit {

  @Input("particleCount")
  public particleCount: number = 200;
  public columns: number = 32;
  public rows: number = 4;
  public size: number = 32;
  public delayMax: number = 2;
  public ltMin: number = 4;
  public ltMax: number = 7;
  public particleArray = 'assets/particle-array.png';
  public ptAnimations: Array<String> = ['ptAnimation0'];

  public particles: Array<Particle> = [];

  constructor() { }

  ngOnInit() {
    for (let i = 0; i < this.particleCount; ++i) {
      let pt = new Particle();
      pt.column =this.randomInt(this.columns);
      pt.row = this.randomInt(this.rows);
      pt.left = this.randomRange(-50, 100);
      pt.top = this.randomRange(50, 100);
      const animation = this.ptAnimations[this.randomInt(this.ptAnimations.length - 1)];
      pt.animation = `ptAnimation0  linear ${this.randomRange(this.ltMin, this.ltMax)}s ${this.randomVal(this.delayMax)}s infinite`;
      this.particles.push(pt);
    }
  }

  randomVal(maxValue: number): number {
    return Math.random() * maxValue;
  }

  randomInt(maxValue: number): number {
    return Math.round(this.randomVal(maxValue));
  }

  randomRange(minValue: number, maxValue: number): number {
    return Math.random() * (maxValue - minValue) + minValue;
  }

}
