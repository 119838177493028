import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InvitationManagerComponent } from './components/invitation-manager/invitation-manager.component';
import { HomeComponent } from './components/home/home.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { WeddingGalleryComponent } from './components/wedding-gallery/wedding-gallery.component';
import { ProgramComponent } from './components/program/program.component';
import { PreparationsComponent } from './components/preparations/preparations.component';
import { ThingsToDoComponent } from './components/things-to-do/things-to-do.component';
import { AccommodationsComponent } from './components/accommodations/accommodations.component';
import {CrystalGalleryModule} from 'ngx-crystal-gallery';
import { ParticlesComponent } from './components/particles/particles.component';
import { AdminComponent } from './components/admin/admin.component';
import { LoginComponent } from './components/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { LightboxModule } from 'ngx-lightbox';

@NgModule({
  declarations: [
    AppComponent,
    InvitationManagerComponent,
    HomeComponent,
    GalleryComponent,
    WeddingGalleryComponent,
    ProgramComponent,
    PreparationsComponent,
    ThingsToDoComponent,
    AccommodationsComponent,
    ParticlesComponent,
    AdminComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CrystalGalleryModule,
    FormsModule,
    ReactiveFormsModule,
    LightboxModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
