import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invitation-manager',
  templateUrl: './invitation-manager.component.html',
  styleUrls: ['./invitation-manager.component.scss']
})
export class InvitationManagerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
