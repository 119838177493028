import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private email: String;
  private password: String;

  private mailAdressControl: FormControl;
  private passwordControl: FormControl;

  constructor() { }

  ngOnInit() {
    this.mailAdressControl = new FormControl(this.email,[Validators.required,Validators.email]);
    this.passwordControl = new FormControl(this.password,Validators.required);
  }

}
